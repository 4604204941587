import { memo, useMemo } from 'react';
import { ModuleData } from '@commons/home';
import { ModuleFactory } from '../ModuleFactory/ModuleFactory';
import { useFilterModules } from './useFilterModules';

interface ModuleListCreatorProps {
  data: ModuleData[];
  startPosition?: number;
  reorderModuleId?: string;
  hasLeftNav?: boolean;
}

const ModuleListItem = memo(
  ({
    module,
    position,
    hasLeftNav,
  }: {
    module: ModuleData;
    position: number;
    hasLeftNav?: boolean;
  }) => (
    <ModuleFactory
      key={module.id}
      type={module.basicModuleData.type}
      module={module}
      position={position}
      hasLeftNav={hasLeftNav}
    />
  ),
);

ModuleListItem.displayName = 'ModuleListItem';

export const ModuleListCreator = ({
  data,
  hasLeftNav,
  reorderModuleId,
  startPosition = 0,
}: ModuleListCreatorProps) => {
  const filterModules = useFilterModules();
  const filteredModules = useMemo(() => data.filter(filterModules), [data, filterModules]);
  const isReorderModule = (moduleId: string) => reorderModuleId === moduleId;

  return (
    <>
      {filteredModules.map((module, index) =>
        !isReorderModule(module?.id) ? (
          <ModuleListItem
            key={module.id}
            module={module}
            position={startPosition + index + 1}
            hasLeftNav={hasLeftNav}
          />
        ) : null,
      )}
    </>
  );
};
